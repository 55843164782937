
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, MyAthleteMixin } from "@/mixins";
import { ProOrganizationSearch } from "@/../types/constants/web_client_user.routes";
import TeamDashboardInfoProvider from '@/components/hoc/TeamDashboardInfoProvider.vue';
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import CircledAvatar from '../components/ui/CircledAvatar.vue';
import { StaffMember } from '../models/team/StaffMember';
import { CoachDetailModel } from "@/models/school/SchoolModel";
import SharingInfoProvider from "@/components/hoc/SharingInfoProvider.vue";
import ShareProfile from "@/components/profile/recruiting/ShareProfile.vue";

@Component({
	components: {
		TeamDashboardInfoProvider,
		RecruitingProfileInfo,
		SharingInfoProvider,
		ShareProfile
	}
})
export default class ProOrganizationDetailPage extends Mixins(VuetifyMixin, MyAthleteMixin) {
	@Prop({required: true}) teamId: string;
	@Prop({required: true}) athleteId: string;

	shareProfileClick: boolean = false;
	Coaches(staff: StaffMember[]): CoachDetailModel[] | null {
		return staff.map((member) => { 
			var coach: CoachDetailModel = new CoachDetailModel();
			coach.name = `${member.firstName} ${member.lastName}`;
			coach.email = member.email;
			return coach;
		})
	}
	CoachesWithEmails(staff: StaffMember[]): string[] {
		return this.Coaches(staff).map(coach => coach.email);
	}
	findCoach(email: string, staff: StaffMember[]): string{
		const coach = this.Coaches(staff).find(x => x.email === email);
		return coach.name + ' | ' + coach.title
	}

	gotoProOrganizations(): void {
		this.$router.push({
			name: ProOrganizationSearch,
			params: {
				...this.$route.params,
				athleteId: this.CurrentAthleteId
			}
		});
	}

	get Loading(): boolean{
		return false;
	}

	FullName(staff: StaffMember): string {
		if( staff.lastName ) {
			if( staff.firstName ) return staff.firstName + " " + staff.lastName;
			return staff.lastName;
		}
		if( staff.firstName) return staff.firstName;
		return null;
	}
}
