
import { Preview } from 'vue-advanced-cropper';
import * as Routes from '@/../types/constants/web_client_user.routes';
import { Component, Mixins } from 'vue-property-decorator';
import Page from './Page.vue';
import AthleteVerifiedAssessmentsCard from '../components/profile/athlete/AthleteVerifiedAssessmentsCard.vue';
import AthleteSelfAssessmentsCard from '../components/profile/athlete/AthleteSelfAssessmentsCard.vue';
import ComingSoonCard from '../components/ui/ComingSoonCard.vue'
import EditOverlay from '../components/ui/EditOverlay.vue'
import TeamMiniCard from '../components/ui/TeamMiniCard.vue'
import StatsSummaryCard from '../components/profile/athlete/StatsSummaryCard.vue'
import { VuetifyMixin, BAIconsMixin, AuthMixin, MyTeamsMixin, MyAthleteMixin, AthleteRoutingMixin, StringsMixin } from '@/mixins';
import { athleteProfile, imagesStore } from '../store';
import MindsetSvg from '../components/svg/MindsetSvg.vue'
import TacticalClipboardSvg from '../components/svg/TacticalClipboardSvg.vue'
import SurveySvg from '../components/svg/SurveySvg.vue'
import GraduateCapSvg from '../components/svg/GraduateCapSvg.vue'
import { AthleteProfileModel } from '../models/athlete/AthleteProfileModel';
import { AthleteAssessmentDataModel } from '../models/athlete/AthleteAssessmentDataModel';
import { SelfAssessmentModel } from '../models/athlete/SelfAssessmentModel';
import { Route } from 'vue-router';
import SubscriptionInfoProvider from '@/components/hoc/SubscriptionInfoProvider.vue';
import ProfilePictureUpload from '@/components/forms/ProfilePictureUpload.vue';
import { SubscriptionModel } from '@/models/stripe/SubscriptionModel';
import { UserSubscriptions } from '@/../types/interfaces';
import { userApi } from '@/api/UserApi';
import AthleteScoutingReportsCard from "@/components/profile/athlete/AthleteScoutingReportsCard.vue";
import AthleteComparativeDataCard from "@/components/profile/athlete/AthleteComparativeDataCard.vue";
import AthleteEventsCard from "@/components/profile/athlete/AthleteEventsCard.vue";
import AthleteScheduleCard from "@/components/profile/athlete/AthleteScheduleCard.vue";
import AthleteMindsetReportCard from "@/components/profile/athlete/AthleteMindsetReportCard.vue"
import AthleteHighlightReelCard from "@/components/profile/athlete/AthleteHighlightReelCard.vue";
import SharingSummaryCard from "@/components/ui/SharingSummaryCard.vue"

@Component({
	components:{
		AthleteVerifiedAssessmentsCard,
		AthleteSelfAssessmentsCard,
		ComingSoonCard,
		EditOverlay,
		ProfilePictureUpload,
		Preview,
		StatsSummaryCard,
		Page,
		MindsetSvg,
		TacticalClipboardSvg,
		SurveySvg,
		GraduateCapSvg,
		TeamMiniCard,
		SubscriptionInfoProvider,
		AthleteEventsCard,
		AthleteScoutingReportsCard,
		AthleteComparativeDataCard,
		AthleteMindsetReportCard,
		AthleteHighlightReelCard,
		AthleteScheduleCard,
		SharingSummaryCard,
	},
	async beforeRouteEnter(to, _, next){
		next((vm: AthleteDashboard) => {
			if(vm.TheAthleteId !== null){
				athleteProfile.getAthleteProfile(vm.TheAthleteId);
			}else{
				athleteProfile.getAthleteProfile(vm.$auth.athleteId);
			}
		});
	},
	async beforeRouteUpdate(_, __, next){
		if(this.TheAthleteId !== null){
			athleteProfile.getAthleteProfile(this.TheAthleteId);
		}else{
			athleteProfile.getAthleteProfile(this.$auth.athleteId);
		}
		next();
	},
})
export default class AthleteDashboard extends Mixins(
		VuetifyMixin,
		BAIconsMixin,
		AuthMixin,
		StringsMixin,
		MyAthleteMixin,
		MyTeamsMixin,
		AthleteRoutingMixin,
	) {

	showHelp: boolean = false;

	mounted() {
		this.showHelp = !athleteProfile.mindsetReport || !athleteProfile.athleteAssessmentData || !athleteProfile.selfAssessmentSummary;
	}

	expandMetric(metric: string, featureAvailable: boolean = false): void{
		if(this.AssessmentData === null) return;
		this.$router.push({
			name: Routes.AthleteComparativeDataSummary,
			params:{
				metric,
				athleteId: this.TheAthleteId,
				featureAvailable: featureAvailable as any
			}
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		}).catch(() => {});
		this.$vuetify.goTo(0);
	}

	get AvatarSize(): { size: number, borderSize: number } {
		if( this.IsMobile ) {
			return {
				size: 75,
				borderSize: 3,
			};
		}

		return {
			size: 150,
			borderSize: 6,
		}
	}

	async setProfilePhoto(pictureUrl: string): Promise<void>{
		imagesStore.setCache({id: this.AthleteProfile.id, url: pictureUrl});
		await athleteProfile.setAthleteProfilePicture({ athleteId: this.TheAthleteId, pictureUrl: pictureUrl});
	}

	get isPageLoading(): boolean{
		return this.IsAthleteLoading || !athleteProfile.AthleteProfileIsReady;
	}
	get AthleteProfileIsReady(): boolean{
		return athleteProfile.AthleteProfileIsReady;
	}
	get AthleteProfile(): AthleteProfileModel{
		return athleteProfile.athleteProfile;
	}
	get IsMyAthleteProfile(): boolean{
		return this.AthleteProfile.id === this.$auth.athleteId;
	}

	get HasContactInformation(): boolean {
		return( this.IsNotEmpty(this.AthleteProfile.Email) || this.IsNotEmpty(this.AthleteProfile.PhoneNumber) );
	}
	get IsContactIncomplete(): boolean {
		return( this.IsEmpty(this.AthleteProfile.Email) || this.IsEmpty(this.AthleteProfile.PhoneNumber) );
	}

	get AssessmentData(): AthleteAssessmentDataModel | null{
		return athleteProfile.athleteAssessmentData;
	}
	get SelfAssessmentData(): SelfAssessmentModel | null{
		return athleteProfile.selfAssessmentSummary;
	}

	viewTeam(teamId: string): void{
		this.$router.push({
			name: Routes.AthleteTeamDashboard,
			params: {
				teamId
			}
		});
	}
	joinTeam(): void{
		this.$router.push(this.RouteToJoinTeam(this.TheAthleteId));
	}

	get BrowseSchoolsRoute(): Partial<Route>{
		return {
			name: Routes.AthleteSchoolSearch,
			params:{
				athleteId: this.TheAthleteId,
			}
		}
	}
	browseSchools(): void{
		this.$router.push(this.BrowseSchoolsRoute);
	}


	get ShowUpgradeNowBanner(): boolean{
		if(this.userSubscriptions === null){
			this.loadUserSubscriptions();
		}else if(this.UserPaidSubscriptions.length === 0){
			return true;
		}
		return false;
	}
	get UserPaidSubscriptions(): SubscriptionModel[] | null{
		if(this.userSubscriptions === null) return null;
		return this.userSubscriptions.athleteProfiles.subscriptions.filter(sub => !sub.plan.free);
	}

	userSubscriptions: UserSubscriptions<SubscriptionModel> | null = null;
	async loadUserSubscriptions(): Promise<void>{
		this.userSubscriptions = await userApi.getUserSubscriptions();
	}
}
