import { render, staticRenderFns } from "./AthleteSelfReportSummary.vue?vue&type=template&id=6fa73ea1&scoped=true&"
import script from "./AthleteSelfReportSummary.vue?vue&type=script&lang=ts&"
export * from "./AthleteSelfReportSummary.vue?vue&type=script&lang=ts&"
import style0 from "./AthleteSelfReportSummary.vue?vue&type=style&index=0&id=6fa73ea1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fa73ea1",
  null
  
)

export default component.exports