
import { Component, Mixins, Prop } from 'vue-property-decorator';
import { CurrentAthleteMixin } from '@/mixins';
import AthleteScoutingReportsCard from "@/components/profile/athlete/AthleteScoutingReportsCard.vue";

@Component({
	components:{
		AthleteScoutingReportsCard,
	}
})
export default class AthleteScoutingReportsView extends Mixins(CurrentAthleteMixin){
}
