
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue'
import { AthleteTeamDashboard, AthleteTeamJoin } from '@/../types/constants/web_client_user.routes';
import { MyAthleteMixin, StringsMixin, SportsSelectorMixin, BAIconsMixin } from '@/mixins';
import { TeamModel, PlayerOnTeam } from '@/models/team';
import { athleteProfile as athleteProfileStore } from '@/store';
import TeamMiniCard from '@/components/ui/TeamMiniCard.vue';
import { athleteApi } from '@/api/AthleteApi';
import { teamApi } from '@/api/TeamApi';

@Component({
	components:{
		Page,
        TeamMiniCard,
	},
})
export default class AthleteTeamsSummary extends Mixins(StringsMixin, MyAthleteMixin, SportsSelectorMixin, BAIconsMixin) {
    get Loading(): boolean{
		return athleteProfileStore.IsLoading || this.IsAthleteLoading || this.UpdatingAthlete;
	}

    get HasTeams(): boolean {
        return( this.IsNotEmptyArray(this.Teams) );
    }
    get Teams(): Array<TeamModel> {
        if( !athleteProfileStore.AthleteProfileIsReady ) return [];
        return athleteProfileStore.athleteProfileTeams;
    }
	get CurrentTeam(): TeamModel {
		if( !this.HasTeams ) return undefined;
		
		const team = this.Teams.find(t => t.id === this.athleteProfile.currentTeam);
		if( this.IsNotEmpty(team) ) return team;
		
		return this.Teams[this.Teams.length - 1];
	}

	viewTeam(teamId: string): void{
		this.$router.push({
			name: AthleteTeamDashboard,
			params: {
				teamId
			}
		});
	}
	joinTeam() {
		this.$router.push({
			name: AthleteTeamJoin,
			params:{
				athleteId: this.TheAthleteId,
			}
		});
	}

    editTeam: TeamModel;
    editPlayer: PlayerOnTeam;
    backupPlayer: PlayerOnTeam;
    editingTeamPlayer: boolean = false;
    async setCurrentTeam(teamId: string) {
        this.updatingTeams = true;
        this.athleteProfile.currentTeam = teamId;
        await athleteApi.save(this.athleteProfile);
        this.updatingTeams = false;
		window.location.reload();
    }
    async setInjuredStatus(isInjured: boolean) {
        for( const team of this.Teams ) {
            const athlete = team.players.find(p => p.athleteId === this.TheAthleteId );
            if( this.IsNotEmpty(athlete) ) {
				athlete.injured = isInjured;
            	teamApi.patchPlayer({teamId: team.id, playerId: athlete.id, playerPatch: athlete});
			}
        }
    }
	async setEligibleStatus(teamId: string, isEligible: boolean) {
		const team = this.Teams.find(t => t.id === teamId);
		if( this.IsEmpty(team) ) return;
		const athlete = team.players.find(p => p.athleteId === this.TheAthleteId );
		if( this.IsNotEmpty(athlete) ) {
			athlete.eligibleToPlay = isEligible;
			teamApi.patchPlayer({teamId: team.id, playerId: athlete.id, playerPatch: athlete});
		}
	}
    async editAthleteOnTeam(teamId: string) {
        if( !this.HasTeams ) return;

        const team = this.Teams.find(t => t.id === teamId);
        if( this.IsEmpty(team) ) return;

        this.editTeam = team;
		this.editPlayer = team.players.find(p => p.athleteId === this.TheAthleteId);
        if( this.IsEmpty(this.editPlayer) ) return;

        this.backupPlayer = Object.assign({}, this.editPlayer);
        this.editingTeamPlayer = true;
    }
	async onCancelEditPlayer() {
		Object.assign(this.editPlayer, this.backupPlayer);
		this.editingTeamPlayer = false;
	}
	async onAcceptEditPlayer() {
		// await this.patchPlayer(this.editPlayer.id, this.editPlayer);
		this.editingTeamPlayer = false;
		this.editPlayer = undefined;
	}
}

