
import * as Routes from '@/../types/constants/web_client_user.routes';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import Page from './Page.vue';
import { VuetifyMixin, AuthMixin, MyTeamsMixin, BAIconsMixin } from '../mixins';
import {athleteProfile} from '../store';

@Component({
	components:{
		Page,
	},
	async beforeRouteEnter(to, _, next){
		next((vm: AthleteSelfAssessmentForm) => {
			if(vm.athleteId !== null){
				athleteProfile.getAthleteProfile(vm.athleteId);
				athleteProfile.loadSelfAssessments({athleteId: vm.athleteId});
			}else{
				athleteProfile.getAthleteProfile(vm.$auth.athleteId);
				athleteProfile.loadSelfAssessments({athleteId: vm.$auth.athleteId});
			}
		});
	},
	async beforeRouteUpdate(_, __, next){
		if(this.athleteId !== null){
			athleteProfile.getAthleteProfile(this.athleteId);
			athleteProfile.loadSelfAssessments({athleteId: this.athleteId});
		}else{
			athleteProfile.getAthleteProfile(this.$auth.athleteId);
			athleteProfile.loadSelfAssessments({athleteId: this.$auth.athleteId});
		}
		next();
	},
})
export default class AthleteSelfAssessmentForm extends Mixins(AuthMixin, MyTeamsMixin, VuetifyMixin, BAIconsMixin){
	@Prop() athleteId: string;
	goBack(): void{
		this.$router.push({
			name: Routes.AthleteSelfReportSummary,
			params:{
				...this.$route.params,
			}
		});
	}	
}
